import React from "react";
import ReactDOM from 'react-dom';
// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/free-mode/free-mode.scss'; // Free mode module

import './styles.css';

// Core modules imports are same as usual
import { FreeMode, Pagination } from 'swiper';

class ContentSlider extends React.Component {

	constructor(props) {
		super(props);
	}

	renderContent = ( content ) => {
		return { __html: content.join('') }
	}

	render() {

		const el2 = ( domstring ) => {
		    const html = new DOMParser().parseFromString( domstring , 'text/html');
		    return html.body.firstChild.children;
		};

		const elements = el2( this.props.content );

		return (

			<Swiper
				slidesPerView={1}
				spaceBetween={10}
				freeMode={true}
				autoHeight={true}
				loop={true}
				pagination={{
					clickable: true,
				}}
				breakpoints={{
					"@0.00": {
						slidesPerView: 1,
						spaceBetween: 10,
					},
					"@0.50": {
						slidesPerView: 1,
						spaceBetween: 10,
					},
					"@0.75": {
						slidesPerView: 2,
						spaceBetween: 20,
					},
					"@1.00": {
						slidesPerView: 3,
						spaceBetween: 20,
					},
					"@1.50": {
						slidesPerView: 3,
						spaceBetween: 20,
					},
				}}
				modules={[FreeMode, Pagination]}
				className="mySwiper"
			>

			{Array.from(elements).map(function(element, index) {
				return (
					<SwiperSlide key={index}><div className="carousel__item" dangerouslySetInnerHTML={{ __html: element.innerHTML }} ></div></SwiperSlide>
				)
			})}

			</Swiper>
		)
	}
}

document.addEventListener('DOMContentLoaded', () => {

	var slider_list = document.querySelectorAll('.content-slider'); // returns NodeList
	var sliders = [...slider_list]; // converts NodeList to Array

	sliders.forEach(slider => {

		let content = slider.innerHTML;
		const data = JSON.parse( slider.getAttribute('data') );

		ReactDOM.render(
			<ContentSlider content={content} data={data} />,
			slider,
		)
	});
})
